import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkCard } from "@egds/react-core/cards";
import { UitkText, UitkHeading } from "@egds/react-core/text";

/**
 * This is the card that's displayed when no packages are available after applying a filter
 */
const NoFilteredPackagesAvailableMessage = React.memo(() => {
  const { formatText } = useLocalization();

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkCard padded>
        <UitkHeading size={7} tag="h2">
          {formatText("maps.filter.error.filterError.header")}
        </UitkHeading>
        <UitkText size={300}>{formatText("maps.filter.error.filterError.message")}</UitkText>
      </UitkCard>
    </UitkSpacing>
  );
});

export default NoFilteredPackagesAvailableMessage;
