import * as React from "react";
import { LocalizedText, useLocalization } from "@shared-ui/localization-context";

import { UitkCardContentSection } from "@egds/react-core/cards";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { UitkExpandoPeek } from "@egds/react-core/expando";
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";

import { DescriptionAndGuestReviewSectionProps } from "components/flexComponents/Packages/typings";
import { Package } from "typings/microserviceModels/packaging-flex-module";
import { UitkText, UitkParagraph } from "@egds/react-core/text";

export const DescriptionAndGuestReviewSection: React.FC<DescriptionAndGuestReviewSectionProps> = ({
  packageItem,
  language,
  renderReviewRating,
  marginOnTop,
  enableExpandoDescription,
}) => {
  if (!packageItem.hotelDescription && !packageItem.showHotelGuestRating && !showFeaturedGuestReview(packageItem)) {
    return null;
  }
  const { formatText } = useLocalization();
  const [isExpandoPeekVisible, setIsExpandoPeekVisible] = React.useState(false);
  const reviewRatingborder: UitkSpacingProps["border"] =
    packageItem.hotelDescription && showFeaturedGuestReview(packageItem) ? ["blockstart"] : [];

  const hotelDescription = enableExpandoDescription ? (
    <UitkSpacing
      padding={{ inline: "three" }}
      margin={{ blockend: "three" }}
      style={{ position: "relative", zIndex: 300 }}
    >
      <UitkExpandoPeek
        className="hotel-descr-package-card"
        lines={3}
        collapseLabel={formatText("read.less.label")}
        expandLabel={formatText("read.more.label")}
        isVisible={isExpandoPeekVisible}
        handleClick={
          /* istanbul ignore next */
          (): void => setIsExpandoPeekVisible((prevIsVisible) => !prevIsVisible)
        }
      >
        <UitkText size={300}>{packageItem.hotelDescription}</UitkText>
      </UitkExpandoPeek>
    </UitkSpacing>
  ) : (
    <UitkSpacing padding={{ inline: "three" }} margin={{ blockend: "three" }}>
      <UitkText overflow={"truncate-3-lines"} size={300}>
        {packageItem.hotelDescription}
      </UitkText>
    </UitkSpacing>
  );

  const hotelDescriptionProvided = packageItem.hotelDescription?.length > 0;
  const reviewRatingPadding: UitkSpacingProps["padding"] = {
    inline: "three",
    block: hotelDescriptionProvided ? "three" : undefined,
    blockend: !hotelDescriptionProvided ? "three" : undefined,
  };

  const hotelGuestRating = (
    <UitkSpacing padding={reviewRatingPadding} border={reviewRatingborder}>
      <UitkCardContentSection padded={false}>
        <ReviewCountAndRating
          hotelOverallReviewRating={Number(packageItem.hotelReviewScore)}
          reviewCount={Number(packageItem.hotelReviewTotal)}
          language={language}
          marginOnTop={marginOnTop}
        />
      </UitkCardContentSection>
    </UitkSpacing>
  );

  const guestReviewBorder = hotelDescriptionProvided && !renderReviewRating ? "top" : undefined;
  const guestReviewPadding: UitkSpacingProps["padding"] = {
    inline: renderReviewRating ? "three" : undefined,
    blockend: renderReviewRating ? "three" : undefined,
    blockstart: !renderReviewRating && hotelDescriptionProvided ? "three" : undefined,
  };

  const guestReview = (
    <UitkSpacing padding={guestReviewPadding}>
      <UitkCardContentSection border={guestReviewBorder} padded={false}>
        <UitkSpacing margin={{ blockend: "two" }}>
          <UitkText overflow={"truncate-3-lines"} size={300}>
            {packageItem.hotelReviewSample}
          </UitkText>
        </UitkSpacing>
        <UitkParagraph size={3} className="reviewedOn" data-testid="hotel-reviewed-on">
          <LocalizedText message="hotels.review.reviewedOn" data={[packageItem.hotelReviewDate]} />
        </UitkParagraph>
      </UitkCardContentSection>
    </UitkSpacing>
  );

  return (
    <>
      {hotelDescriptionProvided && hotelDescription}
      {renderReviewRating && packageItem.showHotelGuestRating && hotelGuestRating}
      {showFeaturedGuestReview(packageItem) && guestReview}
    </>
  );
};

const showFeaturedGuestReview = (packageItem: Package) => {
  return packageItem.showHotelReview && packageItem.hotelReviewSample !== null && packageItem.hotelReviewSample !== "";
};

export default DescriptionAndGuestReviewSection;
