import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkFigure } from "@egds/react-core/images";
import { UitkLinkSizes } from "@egds/react-core/link";
import { UitkText } from "@egds/react-core/text";
import { UitkExpandoLink } from "@egds/react-core/expando";
import { AnalyticsConfig } from "@egds/react-core/utils";

import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import HotelRating from "components/flexComponents/Packages/components/shared/HotelRating";
import CardTitle from "components/flexComponents/Packages/components/shared/CardTitle";
import AddressAndNeighborhood from "components/flexComponents/Packages/components/shared/AddressAndNeighborhood";
import LobBadge from "components/flexComponents/Packages/components/shared/LobBadge";
import SavingBadge from "components/flexComponents/Packages/components/shared/SavingBadge";
import LobHotelDetails from "components/flexComponents/Packages/components/shared/LobHotelDetails";
import LobCarDetails from "components/flexComponents/Packages/components/shared/LobCarDetails";
import HotelRecipe from "../shared/HotelRecipe";
import { splitLocaleIntoLanguageAndCountryCode } from "components/flexComponents/Packages/components/FlightHotel/PackageCards";
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";
import { getFormattedHotelDates } from "components/flexComponents/Packages/utils/PackageDates";
import { TruncateLines } from "components/flexComponents/Packages/utils/PackagesEnum";

import { BlossomImage } from "components/shared/BlossomImage/BlossomImage";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Package } from "typings/microserviceModels/packaging-flex-module";

interface ImageLeftExpandoProps {
  context: ExtendedContextStore;
  packageItem: Package;
  index: number;
}

const PackagesCardMobile = (props: ImageLeftExpandoProps) => {
  const { formatText } = useLocalization();
  const track = useClickTracker();

  const { context, packageItem, index } = props;
  const {
    hotelImageUrl,
    hotelName,
    hotelStarRating,
    hotelReviewScore,
    hotelReviewTotal,
    isoCheckInDate,
    isoCheckOutDate,
    numberOfNights,
    markers,
    packagePriceNoSavings,
    formattedPrice,
    shopUrl,
    car,
  } = packageItem;
  const { localisedCategory: carCategory } = { ...car };

  const language = splitLocaleIntoLanguageAndCountryCode(context.locale);

  const [isExpandoVisible, setIsExpandoVisible] = React.useState(false);

  /* istanbul ignore next */
  const expandoLinkAnalytics: AnalyticsConfig = {
    id: "LP.Packages.HotelCar.ImageExpandoLeftView.ExpandoLink",
    callback: (rfrr) => {
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: rfrr,
        action: Action.CLICK,
      };
      sendDelayedClickstreamTrackEvent(flexTrackingInfo, track);
    },
  };

  return (
    <UitkCard>
      <UitkCardContentSection>
        <UitkLayoutFlex direction="column">
          <UitkLayoutFlex>
            <UitkLayoutFlexItem minWidth="third_width" shrink={0}>
              <div>
                <UitkFigure ratio={UitkFigure.AspectRatio.R4_3} className="uitk-card-roundcorner-all" imageFit="cover">
                  <BlossomImage src={hotelImageUrl} alt={hotelName} imageRatio="4-3" />
                </UitkFigure>
              </div>
            </UitkLayoutFlexItem>

            <UitkLayoutFlexItem grow={1}>
              <UitkSpacing margin={{ inlinestart: "three" }}>
                <UitkLayoutFlex direction="column" justifyContent="space-between">
                  <HotelRating rating={hotelStarRating} isText />
                  <CardTitle cardTitle={hotelName} truncateLines={TruncateLines.TRUNCATE_TWO_LINES} />
                  <AddressAndNeighborhood packageItem={packageItem} />
                  <ReviewCountAndRating
                    hotelOverallReviewRating={Number(hotelReviewScore)}
                    reviewCount={Number(hotelReviewTotal)}
                    language={language}
                  />
                </UitkLayoutFlex>
              </UitkSpacing>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>

          <UitkSpacing margin={{ blockstart: "three" }}>
            <UitkLayoutFlex justifyContent="space-between">
              <UitkLayoutFlex direction="column" justifyContent="end">
                <LobBadge />
                <UitkText size={200} spacing="one">
                  {formatText("package.card.hotel.car.nightStay", numberOfNights)}
                </UitkText>
                <UitkText size={200} weight="bold" spacing="one">
                  {getFormattedHotelDates({
                    checkInDate: isoCheckInDate,
                    checkOutDate: isoCheckOutDate,
                  })}
                </UitkText>
              </UitkLayoutFlex>
              <UitkLayoutFlex direction="column" alignItems="end">
                <SavingBadge markers={markers} />
                <PriceLockup
                  isStrikeThroughFirst
                  strikeThroughPrice={packagePriceNoSavings}
                  lockupPrice={formattedPrice}
                  priceSubtextFirst={formatText("recentSearches.perPerson")}
                  a11yPrice={formatText("package.card.price.lockup.a11y.text", packagePriceNoSavings, formattedPrice)}
                />
              </UitkLayoutFlex>
            </UitkLayoutFlex>
          </UitkSpacing>
        </UitkLayoutFlex>

        <UitkCardLink>
          <TrackedLink
            moduleName="packages"
            rfrr={`hotel-car-card-${index}`}
            href={shopUrl}
            aria-label={formatText("package.card.ariaLabel.hc")}
            data-testid={`packages-hotel-car-image-left-expando-card-${hotelName}`}
          />
        </UitkCardLink>
      </UitkCardContentSection>

      <UitkCardContentSection border="top" padded={false}>
        <UitkSpacing padding={{ inline: "three" }}>
          <UitkExpandoLink
            size={UitkLinkSizes.get("small")}
            isVisible={isExpandoVisible}
            expandoTitle={formatText("recentSearches.mobile.collapsedButton")}
            expandedLabel={formatText("recentSearches.mobile.expandedButton")}
            analytics={expandoLinkAnalytics}
            onToggle={() => setIsExpandoVisible((prevIsVisible) => !prevIsVisible)}
            data-testid={`expando-hotel-details-${index}`}
          >
            <UitkCardContentSection border="bottom">
              <LobHotelDetails
                isoCheckInDate={isoCheckInDate}
                isoCheckOutDate={isoCheckOutDate}
                numberOfNights={numberOfNights}
              />
              <LobCarDetails carCategory={carCategory} />
            </UitkCardContentSection>
            <UitkCardContentSection>
              <HotelRecipe packageItem={packageItem} />
            </UitkCardContentSection>
          </UitkExpandoLink>
        </UitkSpacing>
      </UitkCardContentSection>
    </UitkCard>
  );
};

export default PackagesCardMobile;
