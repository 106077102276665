import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkPrimaryButton } from "@egds/react-core/button";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";

import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";

const ButtonTracked = FlexClickTracker(UitkPrimaryButton);

const ChooseYourDates = () => {
  const { formatText } = useLocalization();

  // Main functionality is tested, only branches with HTML selectors are not covered
  /* istanbul ignore next */
  const scrollToWizardAndFocusOnDate = React.useCallback(() => {
    const bundledPackageSelector = "#wizard-package-pwa-1";
    const allInclusiveSelector = "#All-inclusive-vacations";
    const allInclusiveWizard = document.querySelector(allInclusiveSelector);
    const activeWizardSelector = allInclusiveWizard?.classList?.contains("active")
      ? allInclusiveSelector
      : bundledPackageSelector;
    const activeWizard = document.querySelector(activeWizardSelector);
    activeWizard?.scrollIntoView({ block: "start", behavior: "smooth" });
    //Focus on the departure date field
    const activeStartDateInput = document.querySelector(`${activeWizardSelector} #d1-btn`) as HTMLElement;
    activeStartDateInput.focus({ preventScroll: true });
  }, []);

  const chooseYourDatesButtonComponent = (isFullWidth: boolean) => (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkLayoutFlex justifyContent="center">
        <ButtonTracked
          onClick={scrollToWizardAndFocusOnDate}
          moduleName="packages"
          rfrr="ChooseYourDatesCTA"
          isFullWidth={isFullWidth}
          data-testid="choose-your-dates"
        >
          {formatText("wizard.sticky.cta.label")}
        </ButtonTracked>
      </UitkLayoutFlex>
    </UitkSpacing>
  );

  return (
    <Viewport>
      <ViewSmall>{chooseYourDatesButtonComponent(true)}</ViewSmall>
      <ViewMedium>{chooseYourDatesButtonComponent(false)}</ViewMedium>
    </Viewport>
  );
};

export default ChooseYourDates;
