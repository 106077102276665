import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkBadge, UitkBadgeTheme, UitkBadgeSize } from "@egds/react-core/badge";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkSpacing } from "@egds/react-core/spacing";

import HotelRating from "../shared/HotelRating";
import CardTitle from "../shared/CardTitle";
import AddressAndNeighborhood from "../shared/AddressAndNeighborhood";
import PricesAndDate from "../shared/PricesAndDate";
import DescriptionAndGuestReviewSectionWrapper from "../shared/DescriptionAndGuestReviewSectionWrapper";
import LobDetails from "../shared/LobDetails";
import LobBadge from "../shared/LobBadge";
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";

import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { PackageCardProps } from "components/flexComponents/Packages/typings";

export const PackageCardImageLeft = ({ packageItem, index, language }: PackageCardProps) => {
  const { formatText } = useLocalization();

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkCard padded>
        <UitkLayoutGrid columns={2}>
          <UitkSpacing margin={{ inlineend: "two" }}>
            <UitkLayoutGridItem colSpan={1}>
              <div>
                <UitkFigure
                  ratio={UitkFigureAspectRatioType.R16_9}
                  className="uitk-card-roundcorner-all"
                  imageFit="cover"
                >
                  <UitkImage src={packageItem.hotelImageUrl} alt={packageItem.hotelName} placeholderImage />
                  <UitkLayoutPosition type="absolute" position={{ top: "two", left: "two" }}>
                    <UitkSpacing padding="one">
                      <UitkBadge theme={UitkBadgeTheme.FAMILY_FRIENDLY} size={UitkBadgeSize.SMALL}>
                        <LobBadge />
                      </UitkBadge>
                    </UitkSpacing>
                  </UitkLayoutPosition>
                </UitkFigure>
              </div>
            </UitkLayoutGridItem>
          </UitkSpacing>
          <UitkLayoutGridItem colSpan={1}>
            <UitkSpacing padding={{ inline: "three" }}>
              <UitkCardContentSection padded={false}>
                <UitkLayoutFlex justifyContent="space-between">
                  <UitkLayoutFlexItem>
                    <div>
                      <HotelRating rating={packageItem.hotelStarRating} isText />
                      <CardTitle cardTitle={packageItem.hotelName} />
                      <AddressAndNeighborhood packageItem={packageItem} />
                      {packageItem.showHotelGuestRating && (
                        <ReviewCountAndRating
                          hotelOverallReviewRating={Number(packageItem.hotelReviewScore)}
                          reviewCount={Number(packageItem.hotelReviewTotal)}
                          language={language}
                        />
                      )}
                      <LobDetails packageItem={packageItem} />
                    </div>
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    <div>
                      <PricesAndDate packageItem={packageItem} renderSavingBadge renderDates={false} />
                    </div>
                  </UitkLayoutFlexItem>
                </UitkLayoutFlex>
                <DescriptionAndGuestReviewSectionWrapper packageItem={packageItem} language={language} marginOnTop />
                <UitkCardLink>
                  <TrackedLink
                    className="uitk-card-link"
                    data-testid={`packagecard-desktop-image-left-link-${index}`}
                    rfrr={`card-${index}`}
                    href={packageItem.shopUrl}
                    aria-label={formatText("package.card.ariaLabel.fh")}
                    moduleName="packages"
                  />
                </UitkCardLink>
              </UitkCardContentSection>
            </UitkSpacing>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkCard>
    </UitkSpacing>
  );
};
