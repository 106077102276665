import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkBadge, UitkBadgeTheme, UitkBadgeSize } from "@egds/react-core/badge";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkFigure } from "@egds/react-core/images";

import LobBadge from "components/flexComponents/Packages/components/shared/LobBadge";
import SavingBadge from "components/flexComponents/Packages/components/shared/SavingBadge";
import HotelRating from "components/flexComponents/Packages/components/shared/HotelRating";
import CardTitle from "components/flexComponents/Packages/components/shared/CardTitle";
import AddressAndNeighborhood from "components/flexComponents/Packages/components/shared/AddressAndNeighborhood";
import HotelRecipe from "../shared/HotelRecipe";
import LobHotelDetails from "components/flexComponents/Packages/components/shared/LobHotelDetails";
import LobCarDetails from "components/flexComponents/Packages/components/shared/LobCarDetails";
import { TruncateLines } from "components/flexComponents/Packages/utils/PackagesEnum";
import { splitLocaleIntoLanguageAndCountryCode } from "components/flexComponents/Packages/components/FlightHotel/PackageCards";
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";
import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";

import { BlossomImage } from "components/shared/BlossomImage/BlossomImage";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Package } from "typings/microserviceModels/packaging-flex-module";

interface PackagesCardProps {
  context: ExtendedContextStore;
  packageItem: Package;
  index: number;
}

const PackagesCard = (props: PackagesCardProps) => {
  const { context, packageItem, index } = props;
  const {
    markers,
    hotelImageUrl,
    hotelName,
    hotelStarRating,
    hotelReviewScore,
    hotelReviewTotal,
    packagePriceNoSavings,
    formattedPrice,
    isoCheckInDate,
    isoCheckOutDate,
    numberOfNights,
    shopUrl,
    car,
  } = packageItem;
  const { localisedCategory: carCategory } = { ...car };

  const { formatText } = useLocalization();
  const { locale } = context;
  const language = splitLocaleIntoLanguageAndCountryCode(locale);

  return (
    <UitkCard>
      <UitkFigure ratio={UitkFigure.AspectRatio.R21_9}>
        <BlossomImage src={hotelImageUrl} alt={hotelName} imageRatio="21-9" />
        <UitkLayoutPosition type="absolute" position={{ top: "two", left: "two" }}>
          <UitkBadge theme={UitkBadgeTheme.NOTIFICATION_2} size={UitkBadgeSize.LARGE}>
            <LobBadge />
          </UitkBadge>
        </UitkLayoutPosition>
      </UitkFigure>

      <UitkLayoutPosition type="relative" cloneElement>
        <UitkCardContentSection>
          <SavingBadge markers={markers} overImage />
          <UitkLayoutFlex justifyContent="space-between">
            <UitkLayoutFlexItem grow={1}>
              <UitkLayoutFlex direction="column">
                <HotelRating rating={hotelStarRating} isText />
                <CardTitle cardTitle={hotelName} truncateLines={TruncateLines.TRUNCATE_TWO_LINES} />
                <AddressAndNeighborhood packageItem={packageItem} />
                <ReviewCountAndRating
                  hotelOverallReviewRating={Number(hotelReviewScore)}
                  reviewCount={Number(hotelReviewTotal)}
                  language={language}
                />
              </UitkLayoutFlex>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem shrink={0}>
              <PriceLockup
                isStrikeThroughFirst
                strikeThroughPrice={packagePriceNoSavings}
                lockupPrice={formattedPrice}
                priceSubtextFirst={formatText("recentSearches.perPerson")}
                a11yPrice={formatText("package.card.price.lockup.a11y.text", packagePriceNoSavings, formattedPrice)}
              />
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
          <HotelRecipe packageItem={packageItem} textSpacing="two" />
        </UitkCardContentSection>
      </UitkLayoutPosition>

      <UitkCardContentSection border="top">
        <LobHotelDetails
          isoCheckInDate={isoCheckInDate}
          isoCheckOutDate={isoCheckOutDate}
          numberOfNights={numberOfNights}
        />
        <LobCarDetails carCategory={carCategory} />
      </UitkCardContentSection>

      <UitkCardLink>
        <TrackedLink
          moduleName="packages"
          rfrr={`hotel-car-card-${index}`}
          href={shopUrl}
          aria-label={formatText("package.card.ariaLabel.hc")}
          data-testid={`packages-hotel-car-image-top-card-${hotelName}`}
        />
      </UitkCardLink>
    </UitkCard>
  );
};

export default PackagesCard;
