import * as React from "react";

import { UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";

import CardTitle from "../shared/CardTitle";
import HotelRating from "../shared/HotelRating";
import AddressAndNeighborhood from "../shared/AddressAndNeighborhood";
import PricesAndDate from "../shared/PricesAndDate";
import FlightInfo from "../shared/FlightInfo";
import DescriptionAndGuestReviewSectionWrapper from "../shared/DescriptionAndGuestReviewSectionWrapper";

import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { PackageCardProps } from "components/flexComponents/Packages/typings";
import { TruncateLines } from "../../utils/PackagesEnum";

export const DefaultCardContents = ({
  packageItem,
  index,
  language,
  overImage,
  grid,
  enableExpandoDescription,
  multiLineTitle,
}: PackageCardProps) => {
  const content = (
    <UitkCardContentSection padded={false}>
      <UitkCardContentSection>
        <UitkLayoutFlex justifyContent="space-between">
          <UitkLayoutFlexItem>
            <div>
              <CardTitle
                cardTitle={packageItem.hotelName}
                truncateLines={multiLineTitle ? TruncateLines.TRUNCATE_TWO_LINES : TruncateLines.TRUNCATE_ONE_LINE}
              />
              <HotelRating rating={packageItem.hotelStarRating} isText={false} />
              <AddressAndNeighborhood packageItem={packageItem} />
            </div>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <div>
              <PricesAndDate packageItem={packageItem} renderSavingBadge renderDates overImage={overImage} />
            </div>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
        <UitkSpacing margin={{ blockstart: "two" }}>
          <UitkLayoutFlex direction="column">
            <UitkLayoutFlexItem>
              <div>
                <FlightInfo packageItem={packageItem} />
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkSpacing>
      </UitkCardContentSection>
      <DescriptionAndGuestReviewSectionWrapper
        packageItem={packageItem}
        language={language}
        renderReviewRating
        enableExpandoDescription={enableExpandoDescription}
      />
      <UitkCardLink>
        <TrackedLink
          className="uitk-card-link"
          rfrr={`card-${index}`}
          data-testid={`packagecard-link-${index}`}
          href={packageItem.shopUrl}
          aria-label={packageItem.hotelName}
          moduleName="packages"
        />
      </UitkCardLink>
    </UitkCardContentSection>
  );
  if (grid) {
    return <UitkLayoutGridItem colSpan={2}>{content}</UitkLayoutGridItem>;
  }

  return content;
};
