import * as React from "react";

import { UitkCard } from "@egds/react-core/cards";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkSpacing } from "@egds/react-core/spacing";

import { DefaultCardContents } from "../DefaultCardContents";
import { PackageCardProps } from "components/flexComponents/Packages/typings";
import { BlossomImage } from "components/shared/BlossomImage/BlossomImage";

export const CarouselCard = ({ packageItem, index, language }: PackageCardProps) => (
  <UitkSpacing margin={{ blockstart: "three" }}>
    <UitkCard>
      <UitkLayoutGrid columns={1} blockSize="97%">
        <UitkLayoutGridItem colSpan={2}>
          <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
            <BlossomImage
              placeholderImage
              src={packageItem.hotelImageUrl}
              alt={packageItem.hotelName}
              additionalProps={{ style: { objectFit: "fill" } }}
            />
          </UitkFigure>
        </UitkLayoutGridItem>
        <UitkLayoutGridItem>
          <DefaultCardContents
            packageItem={packageItem}
            index={index}
            language={language}
            overImage={false}
            grid
            enableExpandoDescription
            multiLineTitle
          />
        </UitkLayoutGridItem>
      </UitkLayoutGrid>
    </UitkCard>
  </UitkSpacing>
);
