import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkText } from "@egds/react-core/text";
import { PackageInfoProps } from "components/flexComponents/Packages/typings";

const FlightInfo = ({ packageItem }: PackageInfoProps) => {
  const { formatText } = useLocalization();
  const flightType = `package.card.flight.${packageItem.nonStop ? "roundtripNonStopIncluded" : "roundtripIncluded"}`;
  const originDestination = "package.card.flight.tripOriginDestination";
  const originDestinationArgs = [
    packageItem.originCityName,
    packageItem.originAirport,
    packageItem.destinationCityName,
    packageItem.destinationAirport,
  ];

  return (
    <>
      <UitkText overflow="truncate" size={300}>
        {formatText(flightType)}
      </UitkText>
      <UitkText overflow="truncate" size={300}>
        {formatText(originDestination, ...originDestinationArgs)}
      </UitkText>
    </>
  );
};

export default FlightInfo;
