import * as React from "react";

import { UitkCard } from "@egds/react-core/cards";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkSpacing } from "@egds/react-core/spacing";

import { DefaultCardContents } from "./DefaultCardContents";

import { PackageCardProps } from "components/flexComponents/Packages/typings";

export const PackageCardMobile = ({ packageItem, index, language }: PackageCardProps) => (
  <UitkSpacing margin={{ blockstart: "three" }}>
    <UitkCard>
      <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
        <UitkImage src={packageItem.hotelImageUrl} alt={packageItem.hotelName} placeholderImage />
      </UitkFigure>
      <UitkLayoutPosition type="relative" cloneElement>
        <DefaultCardContents packageItem={packageItem} index={index} language={language} overImage />
      </UitkLayoutPosition>
    </UitkCard>
  </UitkSpacing>
);
