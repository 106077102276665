import * as React from "react";
import { observer } from "mobx-react";
import { logger } from "bernie-core";

import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { Level, liveAnnounce } from "@egds/react-core/utils";

import NoFilteredPackagesAvailableMessage from "./components/shared/NoFilteredPackagesAvailableMessage";
import SeeAllButton from "./components/shared/PackageSeeAllButton";
import PillFilters from "./components/PillFilters";
import { PillId } from "./utils/PackagesEnum";

import { PackagesProps, PackageFilterParams } from "./typings";
import { PackagingFlexModuleResult } from "typings/microserviceModels/packaging-flex-module";
import { withStores } from "stores";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { useLocalization } from "@shared-ui/localization-context";
import PackagesMainContent from "./components/PackagesMainContent";

/**
 *
 * This component displays a list of Package Cards sourced through
 * lpt-flex-web -> package flex module -> DSE or Deals Syndication Engine. Check out DSE here:
 * https://confluence.expedia.biz/display/PDS/DSE+v2+-+DSE+Engine
 *
 * The component also supports a list of filters that can be applied to the query.
 *
 */
@logger("Packages")
class Packages extends React.Component<PackagesProps> {
  render() {
    return <PackagesComponent {...this.props} />;
  }
}

export const PackageDealTypeContext = React.createContext("");

const PackagesComponent = withStores(
  "flexModuleModelStore",
  "compositionStore",
  "packagePillStore",
  "context"
)(
  observer((props: PackagesProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;
    if (!templateComponent) {
      return null;
    }

    const { id } = templateComponent.metadata;
    // ACMG-318 - getModel() will return the model as null when ajax response contains empty flag tru, making section disappear
    // using get(id)?.model instead allows performing the hasResults check on the client and render the overfilter message with way back to remove them
    const model = flexModuleModelStore.get(id)?.model as PackagingFlexModuleResult | null;

    if (!model) {
      return null;
    }

    const { formatText } = useLocalization();
    const {
      empty,
      title,
      packageDealType,
      packages,
      showPillFilter,
      showViewAllTrips,
      seeAllPackagesUrl,
      originName,
      pricesLegalDisclaimer,
    } = model;

    const hasResults = Boolean(!empty && packages?.length);
    const removeFiltersA11yText = formatText("package.pills.removeFilters.a11y");
    const removeFiltersWithPeriodA11yText = `${removeFiltersA11yText}.`;

    const [lengthOfStay, setLengthOfStay] = React.useState("");
    const [starRating, setStarRating] = React.useState("");
    const [flightClass, setFlightClass] = React.useState("");
    const [removeFiltersLiveAnnounceState, setRemoveFiltersLiveAnnounceState] = React.useState(false);
    const [hasInitiallyRendered, setHasInitiallyRendered] = React.useState(false);

    if (!hasInitiallyRendered && !hasResults) {
      return null;
    }

    React.useEffect(() => {
      if (hasInitiallyRendered) {
        const { blossomComponent, compositionStore } = props;

        const params: PackageFilterParams = {
          fmId: blossomComponent?.fmId || 0,
          lengthOfStay,
          starRating,
          flightClass,
        };

        compositionStore.updateModule(params);
      }
    }, [lengthOfStay, starRating, flightClass]);

    React.useEffect(() => {
      setHasInitiallyRendered(true);
    }, []);

    const memoizedPackageDealType = React.useMemo(() => packageDealType.toLowerCase(), []);
    const memoizedTitle = React.useMemo(() => title, []);
    const memoizedShouldShowPillFilter = React.useMemo(() => showPillFilter, []);

    const fetchPackages = (pillId: string, pillOption: any) => {
      if (pillId === PillId.TRIP_LENGTH) {
        setLengthOfStay(pillOption.lptParamValue);
      }

      if (pillId === PillId.STAR_RATING) {
        setStarRating(pillOption.lptParamValue);
      }

      if (pillId === PillId.FLIGHT_CLASS) {
        setFlightClass(pillOption.lptParamValue);
      }
    };

    const removeAllFilters = () => {
      const { compositionStore, packagePillStore } = props;

      setLengthOfStay("");
      setStarRating("");
      setFlightClass("");

      compositionStore.updateModule({ fmId: props.blossomComponent?.fmId || 0 });
      packagePillStore.reset();

      // workaround since aria-live/liveAnnounce() won't announce a message multiple times if the content is identical
      const a11yAnnouncement = removeFiltersLiveAnnounceState ? removeFiltersA11yText : removeFiltersWithPeriodA11yText;

      liveAnnounce(a11yAnnouncement, Level.ASSERTIVE);
      setRemoveFiltersLiveAnnounceState(!removeFiltersLiveAnnounceState);
    };

    return (
      <LazyLoad context={context}>
        <PackageDealTypeContext.Provider value={memoizedPackageDealType}>
          <UitkSpacing padding={{ blockstart: "three" }}>
            <div id={id} className="Packages">
              <UitkHeading size={4} tag="h2">
                {memoizedTitle}
              </UitkHeading>
              {pricesLegalDisclaimer && (
                <UitkSpacing margin={{ block: "one" }}>
                  <UitkText align="left">{pricesLegalDisclaimer}</UitkText>
                </UitkSpacing>
              )}
              {memoizedShouldShowPillFilter && (
                <PillFilters fetchPackages={fetchPackages} removeAllFilters={removeAllFilters} />
              )}

              {hasResults ? (
                <PackagesMainContent context={context} model={model} />
              ) : (
                <NoFilteredPackagesAvailableMessage />
              )}

              {showViewAllTrips && (
                <SeeAllButton context={context} seeAllUrl={seeAllPackagesUrl} originName={originName} />
              )}
            </div>
          </UitkSpacing>
        </PackageDealTypeContext.Provider>
      </LazyLoad>
    );
  })
);

export default Packages;
