import * as React from "react";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";

import { PackageDealTypeContext } from "components/flexComponents/Packages/Packages";
import { PackageDealType } from "components/flexComponents/Packages/utils/PackagesEnum";

const LobBadge = () => {
  const packageDealType = React.useContext(PackageDealTypeContext);

  const iconSize = UitkIconSize.EXTRA_SMALL;

  const BadgeComponent = React.useMemo(() => {
    switch (packageDealType) {
      case PackageDealType.FLIGHT_HOTEL:
        return (
          <UitkLayoutFlex direction="row">
            <UitkIcon name="lob_hotels" size={iconSize} />
            <UitkIcon name="add" size={iconSize} />
            <UitkIcon name="lob_flights" size={iconSize} />
          </UitkLayoutFlex>
        );
      case PackageDealType.HOTEL_CAR:
        return (
          <UitkLayoutFlex direction="row">
            <UitkIcon name="lob_hotels" size={iconSize} />
            <UitkIcon name="add" size={iconSize} />
            <UitkIcon name="lob_cars" size={iconSize} />
          </UitkLayoutFlex>
        );
      default:
        return null;
    }
  }, []);

  return BadgeComponent;
};

export default LobBadge;
