import * as React from "react";

import { UitkCard } from "@egds/react-core/cards";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkSpacing } from "@egds/react-core/spacing";

import { DefaultCardContents } from "./DefaultCardContents";

import { PackageCardProps } from "components/flexComponents/Packages/typings";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";

export const PackageCard = ({ packageItem, index, language }: PackageCardProps) => (
  <UitkSpacing margin={{ blockstart: "three" }}>
    <UitkCard border>
      <UitkLayoutGrid columns={3}>
        <UitkLayoutGridItem colSpan={1}>
          <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
            <BlossomImage src={packageItem.hotelImageUrl} alt={packageItem.hotelName} />
          </UitkFigure>
        </UitkLayoutGridItem>
        <DefaultCardContents packageItem={packageItem} index={index} language={language} overImage={false} grid />
      </UitkLayoutGrid>
    </UitkCard>
  </UitkSpacing>
);
