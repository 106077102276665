import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkDealBundledBadge } from "@egds/react-core/badge";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkSpacing } from "@egds/react-core/spacing";

import { PackageDealTypeContext } from "components/flexComponents/Packages/Packages";
import {
  PackageDealType,
  PackageDealMarkerSticker,
  SavingBadgeTextLocKeys,
} from "components/flexComponents/Packages/utils/PackagesEnum";

import { PackageDealTypeMarkerMap, SavingBadgeProps } from "components/flexComponents/Packages/typings";
import { MarkerType } from "typings/microserviceModels/packaging-flex-module";

const SavingBadge: React.FC<SavingBadgeProps> = ({ markers, renderSpace, overImage }) => {
  const packageDealType = React.useContext(PackageDealTypeContext);

  const packageDealTypeMarkerMap: PackageDealTypeMarkerMap = React.useMemo(
    () => ({
      [PackageDealType.FLIGHT_HOTEL]: PackageDealMarkerSticker.FREE_FLIGHT,
      [PackageDealType.HOTEL_CAR]: PackageDealMarkerSticker.FREE_CAR,
    }),
    []
  );

  const savingBadgeTextMap = React.useMemo(
    () => ({
      [PackageDealType.FLIGHT_HOTEL]: SavingBadgeTextLocKeys.FLIGHT_SAVINGS,
      [PackageDealType.HOTEL_CAR]: SavingBadgeTextLocKeys.CAR_SAVINGS,
    }),
    []
  );

  const hasFreeProductMarker = markers?.some(
    (marker: MarkerType) =>
      marker.sticker === packageDealTypeMarkerMap[packageDealType as keyof PackageDealTypeMarkerMap]
  );

  const { formatText } = useLocalization();
  const savingBadge = (
    <UitkDealBundledBadge
      text={formatText(savingBadgeTextMap[packageDealType as keyof PackageDealTypeMarkerMap], 100)}
    />
  );

  if (hasFreeProductMarker) {
    if (overImage) {
      return (
        <UitkLayoutPosition type="absolute" position={{ top: "minus-three", right: "two" }}>
          {savingBadge}
        </UitkLayoutPosition>
      );
    }

    return savingBadge;
  }

  return renderSpace ? (
    <UitkSpacing margin={{ small: { block: "one" }, medium: { block: "unset" } }}>
      <div />
    </UitkSpacing>
  ) : null;
};

export default SavingBadge;
