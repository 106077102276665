import { PackageDatesProps } from "components/flexComponents/Packages/typings";
import { useLocalization } from "@shared-ui/localization-context";

export const getFormattedHotelDates = ({ checkInDate, checkOutDate }: PackageDatesProps) => {
  const { formatText, formatDateString } = useLocalization();
  const dateFormat = "MMMd";
  const checkInFormatted = formatDateString(checkInDate, {
    skeleton: dateFormat,
  });
  const checkOutFormatted = formatDateString(checkOutDate, {
    skeleton: dateFormat,
  });

  return formatText("hotels.whenBooking", checkInFormatted, checkOutFormatted);
};
