import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkText } from "@egds/react-core/text";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { UitkSpacing } from "@egds/react-core/spacing";

import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

interface SeeAllButtonProps {
  context: ExtendedContextStore;
  seeAllUrl: string;
  originName: string;
}

const ButtonTracked = FlexClickTracker(UitkSecondaryButton);

const SeeAllButton = (props: SeeAllButtonProps) => {
  const { formatText } = useLocalization();

  const { context, seeAllUrl, originName } = props;

  const { searchContext } = context;
  const locationName = searchContext.location?.localizedName ?? searchContext.destination?.localizedName;

  const shouldDisplayMoreTripsMessage = Boolean(locationName && originName);

  return (
    <UitkSpacing margin={{ blockstart: "two" }}>
      <UitkLayoutFlex direction="column" alignItems="center">
        <UitkText size={300} weight="bold">
          {formatText("hotels.lookingFor")}
        </UitkText>

        {shouldDisplayMoreTripsMessage && (
          <UitkText size={300}>{formatText("package.seeAllAvailableProperties", originName, locationName)}</UitkText>
        )}

        <UitkSpacing margin={{ blockstart: "two" }}>
          <ButtonTracked moduleName="packages" rfrr="ViewAllTrips" href={seeAllUrl}>
            {formatText("package.viewAllTrips")}
          </ButtonTracked>
        </UitkSpacing>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

export default SeeAllButton;
