import * as React from "react";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkText } from "@egds/react-core/text";

import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { Amenity } from "typings/microserviceModels/packaging-flex-module";

const HotelAmenities = (props: { hotelAmenities: Amenity[] }) => {
  const { hotelAmenities } = props;

  if (!hotelAmenities?.length) {
    return null;
  }

  const hotelAmenitiesKeyHelper = new ItemKeyHelper("packagesHotelCarImageTopCardHotelAmenities");

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkLayoutGrid columns={2} space="one">
        {hotelAmenities.map((amenity) => (
          <UitkLayoutFlex key={hotelAmenitiesKeyHelper.next()}>
            <UitkIcon name={amenity.code.toLowerCase()} size={UitkIconSize.SMALL} />
            <UitkSpacing margin={{ inlinestart: "two" }}>
              <UitkText size={300}>{amenity.text}</UitkText>
            </UitkSpacing>
          </UitkLayoutFlex>
        ))}
      </UitkLayoutGrid>
    </UitkSpacing>
  );
};

export default HotelAmenities;
