import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";

import PackagesCardMobile from "./PackagesCardMobile";
import PackagesCardDesktop from "./PackagesCardDesktop";

import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { PackagingFlexModuleResult } from "typings/microserviceModels/packaging-flex-module";

interface ImageLeftExpandoProps {
  context: ExtendedContextStore;
  model: PackagingFlexModuleResult;
}

const ImageLeftExpando = (props: ImageLeftExpandoProps) => {
  const { context, model } = props;
  const { packages } = model;

  const packagesCardKeyHelper = new ItemKeyHelper("packagesHotelCarImageLeftExpandoCard");

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkLayoutGrid columns={{ small: 1, medium: 1, large: 1 }} space="three" className="imageExpandoLeft">
        {packages.map((packageItem, index) => (
          <Viewport key={packagesCardKeyHelper.next()}>
            <ViewSmall>
              <PackagesCardMobile context={context} packageItem={packageItem} index={index} />
            </ViewSmall>
            <ViewMedium>
              <PackagesCardDesktop context={context} packageItem={packageItem} index={index} />
            </ViewMedium>
          </Viewport>
        ))}
      </UitkLayoutGrid>
    </UitkSpacing>
  );
};

export default ImageLeftExpando;
