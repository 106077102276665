import * as React from "react";

import { PackageCard } from "./PackageCard";
import { PackageCardImageLeftMobile } from "./PackageCardImageLeftMobile";
import { PackageCardImageLeft } from "./PackageCardImageLeft";
import { PackageCardMobile } from "./PackageCardMobile";
import ChooseYourDates from "components/flexComponents/Packages/components/shared/ChooseYourDates";

import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { PackageCardsProps } from "components/flexComponents/Packages/typings";
import CarouselView from "./Carousel/CarouselView";

const keyHelper = new ItemKeyHelper("PackageCards");

export const PackageCards = ({ context, packages, isMobile, viewType, showChooseYourDates }: PackageCardsProps) => {
  const languageAndCountryCodeArray = splitLocaleIntoLanguageAndCountryCode(context.locale);
  viewType = viewType || "default";
  const componentMap: any = {
    "image-left-expando-mobile": PackageCardImageLeftMobile,
    "image-left-expando": PackageCardImageLeft,
    default: PackageCard,
    "default-mobile": PackageCardMobile,
  };

  const PackageCardComponent = componentMap[`${viewType}${isMobile ? "-mobile" : ""}`] ?? PackageCardMobile;

  return (
    <>
      {viewType === "carousel" ? (
        <CarouselView packages={packages} context={context} />
      ) : (
        packages?.map((packageItem, index: number) => (
          <PackageCardComponent
            packageItem={packageItem}
            key={keyHelper.next()}
            index={index}
            language={languageAndCountryCodeArray}
          />
        ))
      )}
      {showChooseYourDates && <ChooseYourDates />}
    </>
  );
};

export const splitLocaleIntoLanguageAndCountryCode = (locale?: string): string[] | undefined => locale?.split(/[_-]/);
