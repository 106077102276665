import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkSpacing, UitkSpacingSpace } from "@egds/react-core/spacing";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkText } from "@egds/react-core/text";

import { getFormattedHotelDates } from "components/flexComponents/Packages/utils/PackageDates";

interface LobHotelDetailsProps {
  isoCheckInDate: string;
  isoCheckOutDate: string;
  numberOfNights: number;
  topSpacing?: UitkSpacingSpace;
}

const LobHotelDetails = (props: LobHotelDetailsProps) => {
  const { isoCheckInDate, isoCheckOutDate, numberOfNights, topSpacing } = props;

  if (!Boolean(isoCheckInDate && isoCheckOutDate && numberOfNights)) {
    return null;
  }

  const { formatText } = useLocalization();

  return (
    <UitkSpacing margin={{ blockstart: topSpacing }}>
      <UitkLayoutFlex space="one">
        <UitkIcon name="lob_hotels" size={UitkIconSize.SMALL} />
        <UitkLayoutFlex direction="column">
          <UitkText size={300} weight="bold" theme="emphasis">
            {getFormattedHotelDates({
              checkInDate: isoCheckInDate,
              checkOutDate: isoCheckOutDate,
            })}
          </UitkText>
          <UitkText size={200}>{formatText("package.card.hotelNightStay", numberOfNights)}</UitkText>
        </UitkLayoutFlex>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

export default LobHotelDetails;
