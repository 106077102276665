import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkPrimaryButton } from "@egds/react-core/button";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkExpandoLink } from "@egds/react-core/expando";
import { UitkLinkSizes } from "@egds/react-core/link";
import { UitkText } from "@egds/react-core/text";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { AnalyticsConfig } from "@egds/react-core/utils";

import HotelRating from "../shared/HotelRating";
import CardTitle from "../shared/CardTitle";
import AddressAndNeighborhood from "../shared/AddressAndNeighborhood";
import PricesAndDate from "../shared/PricesAndDate";
import DescriptionAndGuestReviewSection from "../shared/DescriptionAndGuestReviewSection";
import SavingBadge from "../shared/SavingBadge";
import LobDetails from "../shared/LobDetails";
import LobBadge from "../shared/LobBadge";
import { getFormattedHotelDates } from "components/flexComponents/Packages/utils/PackageDates";
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";

import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

import { LobSummaryProps, PackageCardProps } from "components/flexComponents/Packages/typings";
import { UitkSpacing } from "@egds/react-core/spacing";

export const PackageCardImageLeftMobile = ({ packageItem, index, language }: PackageCardProps) => {
  const TrackedButton = FlexClickTracker(UitkPrimaryButton);
  const { formatText } = useLocalization();
  const packageType = "fh";
  const track = useClickTracker();
  const [isExpandoVisible, setIsExpandoVisible] = React.useState(false);
  const expandoLinkAnalytics: AnalyticsConfig = {
    id: `Packages.${index.toString()}.ImageLeftExpando.ExpandoLink`,
    callback: (rfrr) => {
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: "packaging",
        rfrr,
        action: Action.CLICK,
      };
      sendDelayedClickstreamTrackEvent(flexTrackingInfo, track);
    },
  };

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkCard>
        <UitkCard padded>
          <UitkLayoutFlex>
            <UitkSpacing margin={{ inlineend: "three" }}>
              <UitkLayoutFlexItem minWidth={80} shrink={0}>
                <div>
                  <UitkFigure ratio={UitkFigureAspectRatioType.R1_1} className="uitk-card-roundcorner-all">
                    <UitkImage src={packageItem.hotelImageUrl} alt={packageItem.hotelName} placeholderImage />
                  </UitkFigure>
                </div>
              </UitkLayoutFlexItem>
            </UitkSpacing>
            <UitkLayoutFlexItem>
              <UitkCardContentSection padded={false}>
                <UitkLayoutFlex justifyContent="space-between">
                  <UitkLayoutFlexItem>
                    <div>
                      <HotelRating rating={packageItem.hotelStarRating} isText />
                      <CardTitle cardTitle={packageItem.hotelName} />
                      <AddressAndNeighborhood packageItem={packageItem} />
                      {packageItem.showHotelGuestRating && (
                        <ReviewCountAndRating
                          hotelOverallReviewRating={Number(packageItem.hotelReviewScore)}
                          reviewCount={Number(packageItem.hotelReviewTotal)}
                          language={language}
                        />
                      )}
                    </div>
                  </UitkLayoutFlexItem>
                </UitkLayoutFlex>
              </UitkCardContentSection>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
          <UitkCardContentSection padded={false}>
            <UitkLayoutFlex direction="row-reverse">
              <SavingBadge markers={packageItem.markers} />
            </UitkLayoutFlex>
            <UitkLayoutFlex justifyContent="space-between" alignItems="end">
              <UitkSpacing padding={{ small: { inlineend: "six" } }}>
                <UitkLayoutFlexItem>
                  <div>
                    <LobBadge />
                    <LobSummary packageItem={packageItem} packageType={packageType} />
                  </div>
                </UitkLayoutFlexItem>
              </UitkSpacing>
              <UitkLayoutFlexItem shrink={0}>
                <div>
                  <PricesAndDate packageItem={packageItem} renderSpace />
                </div>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkCardContentSection>
          <UitkCardLink>
            <TrackedLink
              className="uitk-card-link"
              data-testid={`packagecard-mobile-image-left-link-${index}`}
              rfrr={`card-${index}`}
              href={packageItem.shopUrl}
              aria-label={formatText("package.card.ariaLabel.fh")}
              moduleName="packages"
            />
          </UitkCardLink>
        </UitkCard>
        <UitkCard border={false}>
          <UitkCardContentSection padded={false} />
          <UitkSpacing margin={{ inline: "three" }}>
            <UitkCardContentSection padded={false} border="top">
              <UitkExpandoLink
                size={UitkLinkSizes.get("small")}
                expandoTitle={formatText("recentSearches.mobile.collapsedButton")}
                expandedLabel={formatText("recentSearches.mobile.expandedButton")}
                isVisible={isExpandoVisible}
                analytics={expandoLinkAnalytics}
                onToggle={() => setIsExpandoVisible((prevIsVisible) => !prevIsVisible)}
                data-testid={`expando-packagecard-mobile-image-left-${index}`}
              >
                <UitkSpacing padding={{ blockend: "three" }}>
                  <UitkCardContentSection padded={false}>
                    <LobDetails packageItem={packageItem} />
                  </UitkCardContentSection>
                </UitkSpacing>
                <UitkSpacing padding={{ blockstart: "three" }}>
                  <UitkCardContentSection border="top" padded={false}>
                    <DescriptionAndGuestReviewSection
                      packageItem={packageItem}
                      language={language}
                      renderReviewRating={false}
                    />
                    <TrackedButton
                      isFullWidth
                      moduleName="packages"
                      rfrr={`card-${index}.select.`}
                      href={packageItem.shopUrl}
                    >
                      {formatText("hotelsViews.expando.select")}
                    </TrackedButton>
                  </UitkCardContentSection>
                </UitkSpacing>
              </UitkExpandoLink>
            </UitkCardContentSection>
          </UitkSpacing>
        </UitkCard>
      </UitkCard>
    </UitkSpacing>
  );
};

const LobSummary: React.FC<LobSummaryProps> = ({ packageItem, packageType }): React.ReactElement | null => {
  const { formatText } = useLocalization();
  const locPath = "package.card.fragment";
  if (packageType === "fh") {
    const flightFragment = formatText(`${locPath}.roundtrip${packageItem.nonStop ? "NonStop" : ""}Flight`);
    const hotelFragment = formatText(`${locPath}.hotelNightStay`, packageItem.numberOfNights);

    return (
      <UitkText size={200} spacing="half">
        <UitkText size={200} inline>
          {formatText("package.card.fragment.sentence", flightFragment, hotelFragment)}
        </UitkText>
        <UitkSpacing margin={{ inlinestart: "one" }}>
          <UitkText size={200} weight="bold" inline>
            {getFormattedHotelDates({
              checkInDate: packageItem.isoCheckInDate,
              checkOutDate: packageItem.isoCheckOutDate,
            })}
          </UitkText>
        </UitkSpacing>
      </UitkText>
    );
  }

  return null;
};
