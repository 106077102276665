import * as React from "react";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkText } from "@egds/react-core/text";

interface LobCarDetailsProps {
  carCategory: string;
}

const LobCarDetails = (props: LobCarDetailsProps) => {
  const { carCategory } = props;

  if (!carCategory) {
    return null;
  }

  return (
    <UitkSpacing margin={{ blockstart: "two" }}>
      <UitkLayoutFlex space="one">
        <UitkIcon name="lob_cars" size={UitkIconSize.SMALL} />
        <UitkText size={300} weight="bold" theme="emphasis">
          {carCategory}
        </UitkText>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

export default LobCarDetails;
