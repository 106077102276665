import * as React from "react";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { AnalyticsConfig } from "@egds/react-core/utils";

import { CarouselCard } from "./CarouselCard";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { PackageCardsCarouselProps } from "../../../typings";

const CarouselView = (props: PackageCardsCarouselProps) => {
  const { formatText } = useLocalization();
  const track = useClickTracker();

  const { packages } = props;

  const packagesCardKeyHelper = new ItemKeyHelper("packagesHotelFlightCarouselCard");

  /* istanbul ignore next */
  const carouselAnalytics: AnalyticsConfig = {
    id: "LP.Packages.HotelFlight.Carousel",
    /* istanbul ignore next */
    callback: (rfrr) => {
      /* istanbul ignore next */
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: rfrr,
        action: Action.SCROLL,
      };
      /* istanbul ignore next */
      sendDelayedClickstreamTrackEvent(flexTrackingInfo, track);
    },
  };

  /* istanbul ignore next */
  if (!packages || packages.length === 0) {
    return null;
  }

  const packagesComponent = packages.map((packageItem, index) => (
    <CarouselCard key={packagesCardKeyHelper.next()} packageItem={packageItem} index={index} />
  ));

  const getViewComponent = (breakPoints: any) => (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <IsomorphicCarousel
        buttonText={{
          nextButton: formatText("carousel.item.next"),
          prevButton: formatText("carousel.item.prev"),
        }}
        itemsVisible={breakPoints}
        peek
        carouselName="packagesHotelFlight"
        analytics={carouselAnalytics}
      >
        {packagesComponent}
      </IsomorphicCarousel>
    </UitkSpacing>
  );

  return (
    <Viewport>
      <ViewSmall>{getViewComponent({ lg: 2, md: 2, sm: 1 })}</ViewSmall>
      <ViewLarge>{getViewComponent({ lg: 3, md: 2, sm: 1 })}</ViewLarge>
    </Viewport>
  );
};

export default CarouselView;
