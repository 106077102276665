import * as React from "react";

import ImageTop from "./ImageTop/ImageTop";
import ImageLeftExpando from "./ImageLeftExpando/ImageLeftExpando";
import ChooseYourDates from "components/flexComponents/Packages/components/shared/ChooseYourDates";

import { ViewType } from "components/flexComponents/Packages/utils/PackagesEnum";
import { HotelCarProps } from "../../typings";

const HotelCar = (props: HotelCarProps) => {
  const { context, model } = props;
  const { viewType, showChooseYourDates } = model;

  const View = React.useMemo(() => {
    switch (viewType) {
      case ViewType.IMAGE_LEFT_EXPANDO:
        return ImageLeftExpando;
      default:
        return ImageTop;
    }
  }, []);

  return (
    <>
      <View context={context} model={model} />
      {showChooseYourDates && <ChooseYourDates />}
    </>
  );
};

export default HotelCar;
