import * as React from "react";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { AnalyticsConfig } from "@egds/react-core/utils";

import PackagesCard from "./PackagesCard";

import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { PackagingFlexModuleResult } from "typings/microserviceModels/packaging-flex-module";

interface ImageTopProps {
  context: ExtendedContextStore;
  model: PackagingFlexModuleResult;
}

const ImageTop = (props: ImageTopProps) => {
  const { formatText } = useLocalization();
  const track = useClickTracker();

  const { context, model } = props;
  const { packages } = model;

  const packagesCardKeyHelper = new ItemKeyHelper("packagesHotelCarImageTopCard");

  /* istanbul ignore next */
  const carouselAnalytics: AnalyticsConfig = {
    id: "LP.Packages.HotelCar.ImageTopView.Carousel",
    callback: (rfrr) => {
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: rfrr,
        action: Action.SCROLL,
      };
      sendDelayedClickstreamTrackEvent(flexTrackingInfo, track);
    },
  };

  const packagesComponent = packages.map((packageItem, index) => (
    <PackagesCard key={packagesCardKeyHelper.next()} context={context} packageItem={packageItem} index={index} />
  ));

  return (
    <Viewport>
      <ViewSmall>
        <UitkSpacing margin={{ blockstart: "three" }}>
          <UitkLayoutGrid columns={{ small: 1, medium: 2 }} space="three">
            {packagesComponent}
          </UitkLayoutGrid>
        </UitkSpacing>
      </ViewSmall>

      <ViewLarge>
        <UitkSpacing margin={{ blockstart: "three" }}>
          <IsomorphicCarousel
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            itemsVisible={{ lg: 3, md: 2, sm: 1 }}
            pageBy={1}
            peek
            carouselName="packagesHotelCar"
            className="imageTopCarousel"
            analytics={carouselAnalytics}
          >
            {packagesComponent}
          </IsomorphicCarousel>
        </UitkSpacing>
      </ViewLarge>
    </Viewport>
  );
};

export default ImageTop;
