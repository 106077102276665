import * as React from "react";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

import { PackageCards } from "./PackageCards";
import { FlightHotelProps } from "../../typings";

const FlightHotel = (props: FlightHotelProps) => {
  const { context, model } = props;
  const { packages, viewType, showChooseYourDates } = model;

  return (
    <Viewport>
      <ViewSmall>
        <PackageCards
          context={context}
          packages={packages}
          isMobile={true}
          viewType={viewType}
          showChooseYourDates={showChooseYourDates}
        />
      </ViewSmall>
      <ViewMedium>
        <PackageCards
          context={context}
          packages={packages}
          isMobile={false}
          viewType={viewType}
          showChooseYourDates={showChooseYourDates}
        />
      </ViewMedium>
    </Viewport>
  );
};

export default FlightHotel;
