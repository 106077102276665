import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkPill } from "@egds/react-core/pill";
import { PositionType, UitkMenu, UitkMenuContainer, UitkMenuTrigger } from "@egds/react-core/menu";

import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";

import { Pill, PillOption } from "components/flexComponents/Packages/utils/PillFiltersConfig";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { PackagePillStore } from "stores/PackagePillStore";
import { withStores } from "stores";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkTertiaryButton } from "@egds/react-core/button";

interface PillFilterProps {
  pill: Pill;
  fetchPackages: (pillId: string, pillOption: PillOption) => void;
  analytics: AnalyticsStore;
  packagePillStore: PackagePillStore;
}

export const PillFilter = withStores(
  "packagePillStore",
  "analytics"
)((props: PillFilterProps) => {
  const { formatText } = useLocalization();

  const { pill, fetchPackages, analytics, packagePillStore } = props;
  const { id: pillId, options: pillOptions } = pill;

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);
  const pillState = packagePillStore.getPillActiveState(pillId);

  const handleItemClick = (pillOption: PillOption) => () => {
    toggleIsOpen();
    fetchPackages(pillId, pillOption);
    packagePillStore.updatePill(pillId, localizedPillDisplayValue(pillOption));

    const trackingInfo: FlexTrackingInfo = {
      moduleName: "packages",
      rfrr: `pill.${pillId}.${pillOption.lptParamValue}`,
      action: Action.CLICK,
    };
    sendDelayedTrackEvent(trackingInfo, analytics);
  };

  const localizedPillDisplayValue = ({ locKey, locArg }: PillOption): string => {
    return locArg
      ? Array.isArray(locArg)
        ? formatText(`${locKey}`, ...locArg)
        : formatText(`${locKey}`, locArg)
      : formatText(`${locKey}`);
  };

  const pillName = packagePillStore.getPillName(pillId) || formatText(pill.name);

  return (
    <UitkMenu isOpen={isOpen} onTriggerClick={toggleIsOpen} id={pillId}>
      <UitkMenuTrigger>
        <UitkPill
          id={`pill-${pillId}`}
          triggerPill
          toggleState={isOpen}
          active={pillState}
          selected={pillState || isOpen}
        >
          <span data-testid="pillName">{pillName}</span>
        </UitkPill>
      </UitkMenuTrigger>
      <UitkMenuContainer position={PositionType.LEFT} width={165}>
        {pillOptions.map((option, index) => (
          <UitkTertiaryButton role="menuitem" key={index} onClick={handleItemClick(option)} tabIndex={0} type="button">
            <UitkSpacing margin={{ inline: "three" }}>
              <UitkText align="left" data-testid={`pill-${pillId}-options`}>
                {localizedPillDisplayValue(option)}
              </UitkText>
            </UitkSpacing>
          </UitkTertiaryButton>
        ))}
      </UitkMenuContainer>
    </UitkMenu>
  );
});
