import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLink } from "@egds/react-core/link";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";

import { PillFilter } from "./PillFilter";
import { PackageDealTypeContext } from "components/flexComponents/Packages/Packages";
import { PackageDealType } from "components/flexComponents/Packages/utils/PackagesEnum";
import {
  Pill,
  PillOption,
  tripLengthPillFilter,
  starRatingPillFilter,
  flightClassPillFilter,
} from "components/flexComponents/Packages/utils/PillFiltersConfig";

import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

interface PillFiltersStore {
  fetchPackages: (pillId: string, pillOption: PillOption) => void;
  removeAllFilters: () => void;
}

const PillFilters = (props: PillFiltersStore) => {
  const { fetchPackages, removeAllFilters } = props;
  const { formatText } = useLocalization();
  const packagesPillFilterskeyHelper = new ItemKeyHelper("PackagesPillFilters");

  const packageDealType = React.useContext(PackageDealTypeContext);

  const pillFiltersMap: any = {
    [PackageDealType.FLIGHT_HOTEL]: [tripLengthPillFilter, starRatingPillFilter, flightClassPillFilter],
    [PackageDealType.HOTEL_CAR]: [tripLengthPillFilter],
  };

  const pillFiltersForPackageDealType = pillFiltersMap[packageDealType];

  return (
    <>
      <UitkSpacing padding={{ blockstart: "two" }}>
        <UitkLayoutFlex wrap="wrap" space="two">
          {pillFiltersForPackageDealType.map((pill: Pill) => (
            <PillFilter key={packagesPillFilterskeyHelper.next()} pill={pill} fetchPackages={fetchPackages} />
          ))}
          <UitkLayoutFlexItem alignSelf="center">
            <UitkLink size="small" inline onClick={removeAllFilters}>
              <button type="button">{formatText("package.pills.removeFilters")}</button>
            </UitkLink>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkSpacing>
    </>
  );
};

export default PillFilters;
