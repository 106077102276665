import * as React from "react";
import { UitkText } from "@egds/react-core/text";
import { Package } from "typings/microserviceModels/packaging-flex-module";

const AddressAndNeighborhood = ({ packageItem }: { packageItem: Package }) => {
  if (!packageItem.showNeighborhood || !packageItem.hotelAddress) return null;

  return <UitkText size={300}>{packageItem.hotelAddress.hotelNeighborhood}</UitkText>;
};

export default AddressAndNeighborhood;
