import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkText, UitkTypeMargin } from "@egds/react-core/text";

import HotelAmenities from "components/flexComponents/Packages/components/shared/HotelAmenities";

import { Package } from "typings/microserviceModels/packaging-flex-module";

interface HotelRecipeProps {
  packageItem: Package;
  textSpacing?: UitkTypeMargin;
}

const HotelRecipe = (props: HotelRecipeProps) => {
  const { packageItem, textSpacing } = props;
  const { hotelDescription, hotelAmenities, hotelReviewSample, hotelReviewDate } = packageItem;

  const { formatText } = useLocalization();

  return hotelDescription ? (
    <>
      <UitkText size={300} spacing={textSpacing} overflow="truncate-3-lines">
        {hotelDescription}
      </UitkText>
      <HotelAmenities hotelAmenities={hotelAmenities} />
    </>
  ) : (
    <>
      <UitkText size={300} spacing={textSpacing} overflow="truncate-2-lines">
        {`\u0022${hotelReviewSample}\u0022`}
      </UitkText>

      <UitkText size={200} spacing="one">
        {formatText("hotels.review.reviewedOn", hotelReviewDate)}
      </UitkText>
    </>
  );
};

export default HotelRecipe;
