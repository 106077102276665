import * as React from "react";

import { PackageDealTypeContext } from "components/flexComponents/Packages/Packages";
import FlightHotel from "./FlightHotel/FlightHotel";
import HotelCar from "./HotelCar/HotelCar";

import { PackageDealType } from "components/flexComponents/Packages/utils/PackagesEnum";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { PackagingFlexModuleResult } from "typings/microserviceModels/packaging-flex-module";

interface PackagesMainContentProps {
  context: ExtendedContextStore;
  model: PackagingFlexModuleResult;
}

const PackagesMainContent = (props: PackagesMainContentProps) => {
  const packageDealType = React.useContext(PackageDealTypeContext);
  const { context, model } = props;

  const packagesDealTypeComponentMap: any = React.useMemo(
    () => ({
      [PackageDealType.FLIGHT_HOTEL]: FlightHotel,
      [PackageDealType.HOTEL_CAR]: HotelCar,
    }),
    []
  );

  const PackagesTypeComponent = packagesDealTypeComponentMap[packageDealType];

  return <PackagesTypeComponent context={context} model={model} />;
};

export default PackagesMainContent;
