import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { useDialog } from "@shared-ui/dialog-context";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { InfoDialog } from "components/shared/Merchandising/Common/InfoDialog/InfoDialog";
import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import { PackageDealTypeContext } from "../../Packages";
import { PackageDealType } from "../../utils/PackagesEnum";

interface PriceWithDiscountProps {
  packagePriceNoSavings: string;
  formattedPrice: string;
}

const PriceWithDiscount = (props: PriceWithDiscountProps) => {
  const { packagePriceNoSavings, formattedPrice } = props;
  const { formatText } = useLocalization();
  const [isDialogOpen, dialogActions, PriceDialogComponent] = useDialog(
    `pricewithdiscount-priceDisclaimer-${packagePriceNoSavings}-${formattedPrice}}`
  );
  const disclaimerRef = React.useRef<HTMLButtonElement>(null);
  const packageDealType = React.useContext(PackageDealTypeContext);

  const closeDialog = () => {
    dialogActions.closeDialog();

    /* istanbul ignore next */
    if (disclaimerRef?.current) {
      disclaimerRef.current.focus();
    }
  };

  return (
    <UitkSpacing padding={{ inlinestart: "half" }}>
      <div aria-hidden="true">
        <UitkLayoutPosition position={{ zindex: "layer2" }}>
          <PriceLockup
            lockupPrice={formattedPrice}
            a11yPrice={formatText("package.card.price.lockup.a11y.text", packagePriceNoSavings, formattedPrice)}
            a11yStrikeThroughDialogTrigger={formatText("multiItem.strikeout.disclaimer.a11yLabel")}
            strikeThroughPrice={packagePriceNoSavings}
            hasMoreInfoTrigger
            onClickMoreInfoTrigger={dialogActions.openDialog}
            disclaimerRef={disclaimerRef}
            buttonStyle={{ zIndex: 300 }}
          />
          <UitkSheetTransition isVisible={isDialogOpen}>
            <PriceDialogComponent>
              <InfoDialog
                infoText={formatText(
                  `multiItem.strikeout.disclaimer.${packageDealType === PackageDealType.FLIGHT_HOTEL ? "fh" : "hc"}`
                )}
                isDialogOpen={isDialogOpen}
                closeDialog={closeDialog}
                buttonText={formatText("multiItem.strikeout.disclaimer.dialogCloseButton")}
                hideToolbar={true}
              />
            </PriceDialogComponent>
          </UitkSheetTransition>
        </UitkLayoutPosition>
      </div>
    </UitkSpacing>
  );
};

export default PriceWithDiscount;
