import * as React from "react";

import { UitkCardContentSection } from "@egds/react-core/cards";
import { UitkSpacing } from "@egds/react-core/spacing";

import DescriptionAndGuestReviewSection from "./DescriptionAndGuestReviewSection";

import { DescriptionAndGuestReviewSectionProps } from "components/flexComponents/Packages/typings";

const DescriptionAndGuestReviewSectionWrapper: React.FC<DescriptionAndGuestReviewSectionProps> = ({
  packageItem,
  language,
  renderReviewRating,
  marginOnTop,
  enableExpandoDescription,
}) => {
  const isDescriptionEmpty =
    !packageItem.hotelDescription &&
    !packageItem.showHotelGuestRating &&
    !(packageItem.showHotelReview && packageItem.hotelReviewSample !== null && packageItem.hotelReviewSample !== "");
  if (isDescriptionEmpty) {
    return null;
  }

  return (
    <UitkSpacing padding={{ blockstart: "three" }}>
      <UitkCardContentSection border="top" padded={false}>
        <DescriptionAndGuestReviewSection
          packageItem={packageItem}
          language={language}
          renderReviewRating={renderReviewRating}
          marginOnTop={marginOnTop}
          enableExpandoDescription={enableExpandoDescription}
        />
      </UitkCardContentSection>
    </UitkSpacing>
  );
};

export default DescriptionAndGuestReviewSectionWrapper;
