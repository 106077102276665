import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkText, UitkHeading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

import { getFormattedHotelDates } from "components/flexComponents/Packages/utils/PackageDates";

import { FlightSubTextProps, LoBRowProps, PackageInfoProps } from "components/flexComponents/Packages/typings";

const LobDetails: React.FC<PackageInfoProps> = ({ packageItem }) => {
  const {
    destinationCityName,
    destinationAirport,
    originCityName,
    originAirport,
    isoCheckInDate,
    isoCheckOutDate,
    numberOfNights,
    nonStop,
    airCabinCode,
  } = packageItem;
  const { formatText } = useLocalization();

  const originDestination = "package.card.flight.tripOriginDestination";

  const shouldDisplayHotelText = isoCheckInDate && isoCheckOutDate && numberOfNights;
  const shouldDisplayFlightText = typeof nonStop !== "undefined" && airCabinCode;
  if (!shouldDisplayHotelText && !shouldDisplayFlightText) {
    return null;
  }

  const returnAirlineDifferent =
    packageItem.carrierCodePerLeg &&
    packageItem.carrierCodePerLeg.length > 1 &&
    packageItem.carrierCodePerLeg[0] !== packageItem.carrierCodePerLeg[1] &&
    packageItem.carrierNamePerLeg !== undefined;

  return (
    <UitkSpacing margin={{ medium: { blockstart: "two" } }}>
      <UitkLayoutFlex direction="column">
        {shouldDisplayHotelText && (
          <LobRow
            icon="lob_hotels"
            text={getFormattedHotelDates({
              checkInDate: isoCheckInDate,
              checkOutDate: isoCheckOutDate,
            })}
            subText={formatText("package.card.hotelNightStay", numberOfNights)}
          />
        )}
        {shouldDisplayFlightText && (
          <LobRow
            icon="lob_flights"
            text={formatText(
              originDestination,
              ...[originCityName, originAirport, destinationCityName, destinationAirport]
            )}
            subText={getFlightSubText({
              airline:
                (packageItem.carrierNamePerLeg && packageItem.carrierNamePerLeg[0]) ??
                (packageItem.carrierCodePerLeg && packageItem.carrierCodePerLeg[0]),
              isNonstop: nonStop,
              cabinClass: airCabinCode,
              returnAirlineDifferent,
            })}
          />
        )}
        {shouldDisplayFlightText && returnAirlineDifferent && (
          <LobRow
            icon="lob_flights"
            text={formatText(
              originDestination,
              ...[destinationCityName, destinationAirport, originCityName, originAirport]
            )}
            subText={getFlightSubText({
              airline: packageItem.carrierNamePerLeg[1] ?? packageItem.carrierCodePerLeg[1],
              isNonstop: nonStop,
              cabinClass: airCabinCode,
              returnAirlineDifferent,
            })}
          />
        )}
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

export default LobDetails;

const LobRow: React.FC<LoBRowProps> = ({ icon, text, subText }: LoBRowProps) => (
  <UitkSpacing margin={{ block: "one" }}>
    <UitkLayoutFlexItem>
      <UitkLayoutFlex>
        <UitkLayoutFlexItem>
          <UitkSpacing margin={{ inlineend: "two" }}>
            <UitkIcon name={icon} size={UitkIconSize.SMALL} />
          </UitkSpacing>
        </UitkLayoutFlexItem>
        <UitkLayoutFlex direction="column">
          <UitkHeading tag="h4" size={7}>
            {text}
          </UitkHeading>
          <UitkText size={200}>{subText}</UitkText>
        </UitkLayoutFlex>
      </UitkLayoutFlex>
    </UitkLayoutFlexItem>
  </UitkSpacing>
);

const getFlightSubText = ({ airline, isNonstop, cabinClass, returnAirlineDifferent }: FlightSubTextProps) => {
  const { formatText } = useLocalization();
  const airlineText = airline ? `${airline} • ` : "";
  const tripText = returnAirlineDifferent
    ? isNonstop
      ? `${formatText("package.card.flight.nonstop")} • `
      : ""
    : isNonstop
    ? `${formatText("package.card.flight.nonstopRoundtrip")} • `
    : `${formatText("package.card.flight.roundtrip")} • `;

  return `${airlineText}${tripText}${cabinClass}`;
};
