import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { useBexApi } from "@shared-ui/bex-api-context";

import { UitkText } from "@egds/react-core/text";
import { UitkRating } from "@egds/react-core/rating";

import { ratingIcon } from "components/utility/RatingUtil";

interface HotelRatingProps {
  rating: string;
  isText: boolean;
}

const HotelRating = ({ rating, isText }: HotelRatingProps) => {
  const { formatText } = useLocalization();
  const { context } = useBexApi();

  if (isText) {
    const ratingText = formatText("hotels.affinity.luxuryAmenities.starProperty", Number(rating), rating);

    return <UitkText size={200}>{ratingText}</UitkText>;
  }

  return <UitkRating rating={rating} icon={ratingIcon(context.locale, context.siteId)} />;
};

export default HotelRating;
