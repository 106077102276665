import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkBadge, UitkBadgeTheme, UitkBadgeSize } from "@egds/react-core/badge";
import { UitkFigure } from "@egds/react-core/images";

import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import LobBadge from "components/flexComponents/Packages/components/shared/LobBadge";
import HotelRating from "components/flexComponents/Packages/components/shared/HotelRating";
import CardTitle from "components/flexComponents/Packages/components/shared/CardTitle";
import AddressAndNeighborhood from "components/flexComponents/Packages/components/shared/AddressAndNeighborhood";
import SavingBadge from "components/flexComponents/Packages/components/shared/SavingBadge";
import LobHotelDetails from "components/flexComponents/Packages/components/shared/LobHotelDetails";
import LobCarDetails from "components/flexComponents/Packages/components/shared/LobCarDetails";
import HotelRecipe from "../shared/HotelRecipe";
import { splitLocaleIntoLanguageAndCountryCode } from "components/flexComponents/Packages/components/FlightHotel/PackageCards";
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";
import { TruncateLines } from "components/flexComponents/Packages/utils/PackagesEnum";

import { BlossomImage } from "components/shared/BlossomImage/BlossomImage";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Package } from "typings/microserviceModels/packaging-flex-module";

interface PackagesCardDesktopProps {
  context: ExtendedContextStore;
  packageItem: Package;
  index: number;
}

const PackagesCardDesktop = (props: PackagesCardDesktopProps) => {
  const { formatText } = useLocalization();

  const { context, packageItem, index } = props;
  const {
    hotelImageUrl,
    hotelName,
    hotelStarRating,
    hotelReviewScore,
    hotelReviewTotal,
    isoCheckInDate,
    isoCheckOutDate,
    numberOfNights,
    markers,
    packagePriceNoSavings,
    formattedPrice,
    shopUrl,
    car,
  } = packageItem;
  const { localisedCategory: carCategory } = { ...car };

  const language = splitLocaleIntoLanguageAndCountryCode(context.locale);

  return (
    <UitkCard border>
      <UitkLayoutFlex>
        <UitkSpacing padding={{ block: "three", inlinestart: "three" }}>
          <UitkLayoutFlexItem minWidth="half_width" shrink={0}>
            <div>
              <UitkFigure ratio={UitkFigure.AspectRatio.R3_2} className="uitk-card-roundcorner-all" imageFit="cover">
                <BlossomImage src={hotelImageUrl} alt={hotelName} imageRatio="3-2" />
                <UitkLayoutPosition type="absolute" position={{ top: "two", left: "two" }}>
                  <UitkBadge theme={UitkBadgeTheme.NOTIFICATION_2} size={UitkBadgeSize.LARGE}>
                    <LobBadge />
                  </UitkBadge>
                </UitkLayoutPosition>
              </UitkFigure>
            </div>
          </UitkLayoutFlexItem>
        </UitkSpacing>

        <UitkLayoutFlexItem grow={1}>
          <UitkCard>
            <UitkCardContentSection>
              <UitkLayoutFlex justifyContent="space-between">
                <UitkLayoutFlex direction="column">
                  <HotelRating rating={hotelStarRating} isText />
                  <CardTitle cardTitle={hotelName} truncateLines={TruncateLines.TRUNCATE_TWO_LINES} />
                  <AddressAndNeighborhood packageItem={packageItem} />
                  <ReviewCountAndRating
                    hotelOverallReviewRating={Number(hotelReviewScore)}
                    reviewCount={Number(hotelReviewTotal)}
                    language={language}
                  />
                  <LobHotelDetails
                    isoCheckInDate={isoCheckInDate}
                    isoCheckOutDate={isoCheckOutDate}
                    numberOfNights={numberOfNights}
                    topSpacing="two"
                  />
                  <LobCarDetails carCategory={carCategory} />
                </UitkLayoutFlex>
                <UitkLayoutFlex direction="column">
                  <SavingBadge markers={markers} />
                  <PriceLockup
                    isStrikeThroughFirst
                    strikeThroughPrice={packagePriceNoSavings}
                    lockupPrice={formattedPrice}
                    priceSubtextFirst={formatText("recentSearches.perPerson")}
                    a11yPrice={formatText("package.card.price.lockup.a11y.text", packagePriceNoSavings, formattedPrice)}
                  />
                </UitkLayoutFlex>
              </UitkLayoutFlex>
            </UitkCardContentSection>
            <UitkCardContentSection border="top">
              <HotelRecipe packageItem={packageItem} />
            </UitkCardContentSection>
          </UitkCard>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>

      <UitkCardLink>
        <TrackedLink
          moduleName="packages"
          rfrr={`hotel-car-card-${index}`}
          href={shopUrl}
          aria-label={formatText("package.card.ariaLabel.hc")}
          data-testid={`packages-hotel-car-image-left-expando-card-${hotelName}`}
        />
      </UitkCardLink>
    </UitkCard>
  );
};

export default PackagesCardDesktop;
