import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkText, UitkHeading } from "@egds/react-core/text";

import SavingBadge from "../shared/SavingBadge";
import PriceWithDiscount from "../shared/PriceWithDiscount";
import { getFormattedHotelDates } from "components/flexComponents/Packages/utils/PackageDates";

import { PricesAndDateProps } from "components/flexComponents/Packages/typings";

const PricesAndDate: React.FC<PricesAndDateProps> = ({
  packageItem,
  renderSavingBadge,
  renderDates,
  renderSpace,
  overImage,
}) => {
  const { formatText } = useLocalization();
  const { packagePriceNoSavings, formattedPrice, isoCheckInDate, isoCheckOutDate, lastUpdateFormatted } = packageItem;

  return (
    <UitkLayoutFlex direction="column" alignItems="end">
      {renderSavingBadge && (
        <SavingBadge markers={packageItem.markers} renderSpace={renderSpace} overImage={overImage} />
      )}
      {packagePriceNoSavings ? (
        <PriceWithDiscount packagePriceNoSavings={packagePriceNoSavings} formattedPrice={formattedPrice} />
      ) : (
        <>
          <span className="is-visually-hidden">{formatText("hotels.price.a11y", formattedPrice)}</span>
          <UitkHeading size={4} tag="h4" aria-hidden="true">
            {formattedPrice}
          </UitkHeading>
        </>
      )}
      <UitkLayoutFlex direction="column" alignItems="end">
        <UitkText size={100}>{formatText("recentSearches.perPerson")}</UitkText>
        {renderDates && (
          <UitkText size={100}>
            {getFormattedHotelDates({
              checkInDate: isoCheckInDate,
              checkOutDate: isoCheckOutDate,
            })}
          </UitkText>
        )}
        {lastUpdateFormatted && <UitkText size={100}>{lastUpdateFormatted}</UitkText>}
      </UitkLayoutFlex>
    </UitkLayoutFlex>
  );
};

export default PricesAndDate;
