import * as React from "react";
import { UitkHeading } from "@egds/react-core/text";
import { TruncateLines } from "components/flexComponents/Packages/utils/PackagesEnum";

interface CardTitleProps {
  cardTitle: string;
  truncateLines?: TruncateLines;
}

const CardTitle = (props: CardTitleProps) => {
  const { cardTitle, truncateLines } = props;

  return (
    <UitkHeading size={5} overflow={truncateLines || TruncateLines.TRUNCATE_ONE_LINE} tag="h3">
      {cardTitle}
    </UitkHeading>
  );
};

export default CardTitle;
